import React from "react";
import { Typography, Box, Grid, Paper, Stack } from "@mui/material";
import image from "../images/pic_1.png";
import "animate.css";
import AboutCard from "../Component/AboutCard.jsx";
import FeatureComponent from "../Component/FeatureComponent.jsx";
import ButtonComponent from "../Component/ButtonComponent.jsx";
import { useNavigate } from "react-router";
import PartnerCard from "../Component/PartnerCard.jsx";
import img1 from "../images/unionbank.jpg";
import img2 from "../images/Canara-Bank-Logo.jpg";
import img3 from "../images/icici.png";
import img4 from "../images/idfc.jpg";
import img5 from "../images/HDFC-Bank-Logo.jpg";
import img6 from "../images/sbi.jpg";
import img7 from "../images/Bank-of-Baroda-Logo.jpg";
import img8 from "../images/axis.jpg";
import Contact from "../Component/Contact.jsx";
import ScrollAnimation from "react-animate-on-scroll";
import ServiceHeader from "../Component/ServiceHeader";

const imageArray = [img6, img5, img3, img4, img2, img1, img7, img8];

const cards = [
  {
    id: 1,
    title: "1500+",
    description:
      "Empowering over 1500 businesses with our tailored loan solutions",
  },
  {
    id: 2,
    title: "50+",
    description:
      " Dotcnnct has proudly designed and launched over 50 websites.",
  },

  {
    id: 3,
    title: "50+",
    description:
      "   Successfully Digital Marketing strategies for over 50 businesses.",
  },

  {
    id: 4,
    title: "80+",
    description: "Dotcnnct ensures legal precision for 80 businesses.",
  },
];

const featureCards = [
  {
    id: 1,
    title: "Buisness Loan",
    content:
      "Dotcnnct is the go-to partner for business growth, offering tailored loans that have empowered over 1500 businesses. Our financing solutions pave the way for success.",
  },
  {
    id: 2,
    title: "Web Development",
    content:
      "In the realm of web development, Dotcnnct stands out, having crafted 50+ distinctive websites. Each site reflects our commitment to excellence, bringing digital visions to life.",
  },
  {
    id: 3,
    title: "Digital Marketing",
    content:
      "Dotcnnct drives digital success, executing impactful marketing strategies for over 50 clients. Our expertise ensures heightened brand visibility and engagement in the online landscape.",
  },
  {
    id: 4,
    title: "Legal Precision",
    content:
      "With legal precision at our core, Dotcnnct has safeguarded the interests of 80+ businesses. Our expert counsel ensures compliance and provides a secure foundation for sustained success.",
  },
];

function Home() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("services/business-loan");
  };

  return (
    <Stack>
      <ServiceHeader
        image={image}
        title={"DotCnnct \n Connecting the dots of your Success"}
        description={
          "Dotcnnct is your strategic partner in business growth, offering aspectrum of services that seamlessly blend innovation and precisionWith a commitment to connecting the dots for success, we specialize in business loans, website design, digital marketing, and legal precision"
        }
      >
        <Box py={5}>
          <Grid container spacing={3}>
            {cards.map((el, index) => (
              <Grid key={el.id} item xs={12} sm={6} md={3} textAlign={"left"}>
                <Paper
                  sx={{
                    p: 2,
                  }}
                  className="animate__animated animate__slideInLeft"
                >
                  <Typography
                    variant="body1"
                    fontSize="25px"
                    textAlign={"left"}
                    gutterBottom
                  >
                    {el.title}
                  </Typography>

                  <Typography variant="body2" component="div" fontSize="17px">
                    {el.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ServiceHeader>

      <AboutCard />

      <Box sx={{ backgroundColor: "#e0e0e0", padding: 4 }}>
        <Typography variant="h3" textAlign="center">
          Featured Services
        </Typography>

        <Grid container spacing={2} marginTop="10px">
          {featureCards.map((item, index) => (
            <Grid item xs={12} md={3} key={item.id}>
              <ScrollAnimation
                animateIn="animate__fadeInLeft"
                animateOnce
                duration={(index + 1) / 2}
              >
                <FeatureComponent {...item} />
              </ScrollAnimation>
            </Grid>
          ))}
        </Grid>
        <Box
          style={{
            margin: "auto",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <ButtonComponent title="All Services" onClick={handleClick} />
        </Box>
      </Box>

      <Stack sx={{ p: 4, textAlign: "center" }}>
        <Typography variant="h2" textAlign={"center"}>
          Our Bank Partners
        </Typography>

        <Grid container spacing={1} justifyContent="center" mt={2}>
          {imageArray.map((imageArray, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ScrollAnimation
                animateIn="animate__fadeInLeft"
                animateOnce
                duration={(index + 1) / 2}
              >
                <PartnerCard image={imageArray} />
              </ScrollAnimation>
            </Grid>
          ))}
        </Grid>
        <ButtonComponent style={{}} title="Learn More" />
      </Stack>
      <Contact />
    </Stack>
  );
}

export default Home;
