import { Box, CardMedia, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ServiceHeader from "../Component/ServiceHeader";
import image from "../images/digital-marketing.jpg";
import WebServices from "../Component/WebServices";

const DigitalMarketing = () => {
  const cards = [
    {
      id: 20,
      title: "Content Marketing",
      description:
        " Content marketing is the backbone of successful digital strategies. By creating and sharing valuable, relevant content, businesses can establish authority, engage their audience, and foster brand loyalty. It goes beyond promoting products, focusing on building meaningful connections with the target audience.",
    },
    {
      id: 21,
      title: "Pay Per Click",
      description:
        "Pay Per Click (PPC) advertising is a powerful digital marketing strategy that allows businesses to drive targeted traffic to their websites by paying for ads only when users click on them. It provides instant visibility on search engines and social media platforms, enabling precise audience targeting based on demographics, interests, and behaviors.",
    },
    {
      id: 22,
      title: "Social Media Marketing",
      description:
        "Social Media Marketing (SMM) is a dynamic strategy leveraging social platforms to connect with a target audience. It involves creating and sharing content to build brand awareness, engage users, and drive website traffic. SMM platforms like Facebook, Instagram, Twitter, and LinkedIn offer diverse advertising options for precise audience targeting.",
    },
    {
      id: 23,
      title: "Marketing",
      description:
        "Marketing is the art and science of connecting products or services with customers. It involves creating, communicating, delivering, and exchanging offerings that have value for stakeholders. In the digital era, marketing encompasses a broad range of strategies, from traditional advertising to online tactics like social media marketing, content creation.",
    },

    {
      id: 25,
      title: "Traffic",
      description:
        "In the digital realm, traffic refers to the flow of visitors to a website or online platform. High-quality traffic is essential for online success, representing potential customers and engagement opportunities. It can be generated organically through effective SEO strategies or through paid channels like Pay Per Click (PPC) advertising. key focus for businesses aiming to thrive in the digital space.",
    },
    {
      id: 26,
      title: "Conversion",
      description:
        "Conversion in digital marketing is the ultimate goal where a visitor takes a desired action, such as making a purchase, filling out a form, or subscribing. It signifies the successful transition of a prospect into a customer, reflecting the effectiveness of a marketing strategy. Understanding the conversion journey involves analyzing user behavior, optimizing website.",
    },
  ];
  return (
    <Stack>
      <ServiceHeader
        image={image}
        title={
          "Digital Triumph: Transformative Marketing Solutions by Dotcnnct"
        }
        description={
          "Elevate your brand's digital presence with Dotcnnct's dynamic digital marketing solutions. Our comprehensive strategies encompass SEO, social media marketing, PPC advertising, and content creation, tailored to enhance visibility and drive meaningful engagement"
        }
      />

      <Stack textAlign={"center"} p={5}>
        <Typography variant="h4">Services We Offer</Typography>

        <Typography variant="caption" fontStyle={"italic"} p={2}>
          Dotcnnct redefines digital marketing with a focus on your unique
          business objectives. From crafting compelling<br></br> content to
          executing strategic campaigns, we go beyond traditional marketing to
          create a digital narrative that resonates.
        </Typography>

        <Grid container spacing={2} p={3}>
          {cards.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <WebServices {...item} />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ pt: 8 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                lineHeight={1.2}
                fontWeight={800}
                textAlign={"left"}
              >
                We<br></br>
                Growth Back<br></br>
                Your<br></br>
              </Typography>

              <Typography variant="h2" textAlign={"left"}>
                Digital Strategy
              </Typography>
              <br></br>
              <Typography
                lineHeight={1.5}
                textAlign={"left"}
                fontWeight={"200"}
                fontSize={{ xs: "15px", md: "15px" }}
                variant="body2"
              >
                With our expertise in analytics, we provide actionable insights
                for continuous optimization, ensuring your brand remains at the
                forefront of the digital conversation. Collaborate with Dotcnnct
                to amplify your online presence, connect with your target
                audience, and stay ahead in the ever-evolving world of digital
                marketing.Harness the power of our data-driven approach, precise
                audience targeting, and innovative techniques to achieve
                measurable results. "
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <CardMedia
                component={"img"}
                height={"100%"}
                width="100%"
                image={image}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DigitalMarketing;
