import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "./ButtonComponent";

const ServiceHeader = ({ title, image, description, onClick, children }) => {
  return (
    <Box
      padding={4}
      pt={15}
      pb={10}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        // display: "flex",
      }}
    >
      <Stack width={{ xs: "100%", md: "40%" }}>
        <Typography
          className="animate__animated animate__slideInLeft"
          variant="h3"
          color={"white"}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          className="animate__animated animate__slideInLeft"
          variant="caption"
          color={"white"}
        >
          {description}
        </Typography>
        <Stack
          className="animate__animated animate__slideInLeft"
          direction={"row"}
        >
          <ButtonComponent onClick={onClick} title={"Learn More"} />
        </Stack>
      </Stack>
      {children}
    </Box>
  );
};

export default ServiceHeader;
