import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Contact from "../Component/Contact";
import ContactForm from "../Component/ContactForm";

function ContactUs() {
  return (
    <Stack>
      <Box
        sx={{
          backgroundColor: "#e0e0e0",
          textAlign: "left",
          p: 5,
          fontWeight: "400",
          pt: 10,
        }}
      >
        <Typography
          className="animate__animated animate__slideInLeft"
          variant="h2"
          sx={{
            width: { xs: "100%", md: "50%" },
          }}
        >
          Contact
        </Typography>

        <Typography
          sx={{
            width: { xs: "100%", md: "50%" },
          }}
          className="animate__animated animate__slideInLeft"
          fontWeight={"200"}
          pt={{ xs: 4, md: 7 }}
          pb={{ xs: 4, md: 8 }}
        >
          From tailored business loans and precision legal support to
          cutting-edge website and app development, topped with strategic
          digital marketing solutions – we connect the dots for your business
          success."
        </Typography>
      </Box>
      <Box>
        <Contact />
      </Box>
      <Box px={{ xs: 4, md: 4 }}>
        <ContactForm />
      </Box>
    </Stack>
  );
}

export default ContactUs;
