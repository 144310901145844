import { Box, CardMedia, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import image from "./../images/business-loan.jpg";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PaidIcon from "@mui/icons-material/Paid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PercentIcon from "@mui/icons-material/Percent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TollIcon from "@mui/icons-material/Toll";
import EMICalculator from "../Component/EMI_Calculator";
import ServiceHeader from "../Component/ServiceHeader";
import BusinessLoanEligibility from "../Component/BusinessLoanEligibility";

const loanFetaures = [
  { id: 2, icon: <ElectricBoltIcon />, title: "Quick loan disbursal" },

  { id: 3, icon: <TouchAppIcon />, title: "Hassle-free Documentation" },

  { id: 4, icon: <WorkOutlineIcon />, title: "Collateral-free business loan" },

  {
    id: 5,
    icon: <PaidIcon />,
    title: "Loans ranging from ₹10 Lakhs to ₹1 Crore",
  },

  { id: 6, icon: <AccessTimeIcon />, title: "Available 24x7" },

  {
    id: 7,
    icon: <PercentIcon />,
    title: "Interest Rates ranging from 9% to 18%.",
  },

  {
    id: 8,
    icon: <CalendarMonthIcon />,
    title: "Tenure upto 6 Years",
  },

  {
    id: 9,
    icon: <TollIcon />,
    title: "Processing Fee as per bank partners",
  },
];

const BusinessLoan = () => {
  return (
    <Stack>
      <ServiceHeader
        title={"Small Business Loans and Financing"}
        description={
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus quasi corrupti et totam ad! Architecto tempore rem iste aut sequi accusantium voluptate minus! Omnis rerum ducimus necessitatibus dicta modi natus."
        }
        image={image}
      />
      <Box bgcolor={"#e0e0e0"} p={4}>
        <Typography variant="h4" textAlign={"center"} gutterBottom mb={10}>
          Features and Benefits of a Business Loan
        </Typography>
        <Grid container spacing={6}>
          {loanFetaures.map((el) => (
            <Grid item xs={6} md={4}>
              <Stack key={el.id} alignItems={"center"} spacing={2}>
                {React.cloneElement(el.icon, { sx: { fontSize: "50px" } })}
                <Typography textAlign={"center"}>{el.title}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={4}>
        <Typography variant="h4" textAlign={"center"} gutterBottom mb={4}>
          Business Loan EMI Calculator
        </Typography>
        <Typography
          variant="caption"
          textAlign={"center"}
          gutterBottom
          display={"block"}
        >
          Planning before availing of your business loan is essential, and it
          involves more than just figuring out your needs and deciding the
          amount. Factors like repayment, EMIs, and interest are also part of
          the loan journey. To make repayment less stressful, DotCnnct offers a
          Business Loan EMI Calculator.
        </Typography>
        <Typography
          variant="caption"
          textAlign={"center"}
          gutterBottom
          display={"block"}
          mb={4}
        >
          You can enter your loan amount, interest rate, and tenure to determine
          your monthly EMI amount. This tool removes the complicated
          calculations and allows you to compare and review which tenure or
          interest rate works best for your needs.
        </Typography>
        <EMICalculator />
      </Box>
      <BusinessLoanEligibility />
      <Box sx={{ p: 4, pt: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              lineHeight={1.2}
              fontWeight={800}
              textAlign={"left"}
            >
              Why Take a Loan?
            </Typography>

            <Typography variant="h2" textAlign={"left"}>
              Empower Your Dreams with Our Customized Loans
            </Typography>
            <br></br>
            <Typography
              lineHeight={1.5}
              textAlign={"left"}
              fontWeight={"200"}
              fontSize={{ xs: "15px", md: "15px" }}
              variant="body2"
            >
              Unlock the doors to financial empowerment and turn your
              aspirations into reality with our tailored loan solutions. We
              understand that life is full of opportunities and challenges, and
              sometimes, a financial boost is all you need to seize them.
              Whether you're looking to expand your business, pursue higher
              education, or embark on a personal venture, our range of loan
              options is designed to cater to your unique needs.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardMedia
              component={"img"}
              height={"100%"}
              width="100%"
              image={image}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default BusinessLoan;
