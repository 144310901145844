import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import image from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import One from "./one";
import ButtonComponent from "./ButtonComponent";

const AboutCard = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <Box padding={4} paddingY={{ xs: 3, md: 6 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={6}>
          <Stack>
            <Typography variant="h3">DotCnnct</Typography>

            <Typography variant="h5">
              Your holistic business solution partner!
            </Typography>

            <Typography
              sx={{ marginTop: "20px" }}
              variant="body2"
              color="text.secondary"
            >
              We specialize in empowering enterprises through our comprehensive
              suite of services. From tailored business loans providing
              financial momentum to captivating website designs that redefine
              your online presence, our team ensures your success. Navigate the
              digital landscape with our results-driven digital marketing
              strategies, and rest easy with our meticulous legal documentation
              services.
            </Typography>
            <ButtonComponent onClick={handleClick} title={"Learn More"} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <One image={image} width="100%" height={"100%"} />
            <One image={image2} width="100%" height={"100%"} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutCard;
