import { Box, Button, useMediaQuery } from "@mui/material";

const ButtonComponent = ({ title, onClick }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const buttonStyles = {
    backgroundColor: "black",
    color: "white",
    padding: "8px 15px",
    borderRadius: "5px",
    fontSize: "12px",
    marginTop: isSmallScreen ? "20px" : "35px",
  };
  return (
    <Box>
      <Button onClick={onClick} style={buttonStyles}>
        {title}
      </Button>
    </Box>
  );
};

export default ButtonComponent;
