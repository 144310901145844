import React from "react";
import image from "./../images/web-designing.jpg";
import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ServiceHeader from "../Component/ServiceHeader";
import WebServices from "../Component/WebServices";

const WebDesigning = () => {
  const cards = [
    {
      id: 11,
      title: "Front-End-Development",
      description:
        "Elevate your digital presence with Dotcnnct's frontend development expertise. From sleek interfaces to responsive design, we craft engaging user experiences that seamlessly blend aesthetics and functionality.In an ever-evolving digital landscape, frontend developers embrace new frameworks and libraries to stay on the cutting edge. Whether it's React, Angular, or Vue.js, these tools empower developers to build robust and scalable user interfaces.",
    },
    {
      id: 12,
      title: "Backend Development",
      description:
        "Elevate your digital presence with Dotcnnct's frontend development expertise. From sleek interfaces to responsive design, we craft engaging user experiences that seamlessly blend aesthetics and functionality.In an ever-evolving digital landscape, frontend developers embrace new frameworks and libraries to stay on the cutting edge. Whether it's React, Angular, or Vue.js, these tools empower developers to build robust and scalable user interfaces.",
    },
    {
      id: 13,
      title: "Content Management ",
      description:
        "Unlock the full potential of your digital presence with Dotcnnct's comprehensive content management solutions. Our web development team seamlessly integrates robust content management systems, offering you a user-friendly interface to effortlessly update and organize your website's content. Whether it's updating product information, publishing blog posts, or managing multimedia, our tailored solutions ensure efficient content workflows.",
    },
    {
      id: 14,
      title: "App Development ",
      description:
        "In app development, Dotcnnct brings innovation to life with user-centric solutions. Our team crafts seamless and intuitive applications tailored to your unique needs, leveraging cutting-edge technologies like React Native and Flutter for cross-platform compatibility. From concept to deployment, we prioritize user experience, ensuring your app not only meets but exceeds expectations where innovation meets functionality for a compelling user experience.",
    },
    {
      id: 15,
      title: "Website Support & Maintenace",
      description:
        "At Dotcnnct, our commitment extends beyond the launch with our Website Support & Maintenance services. We provide ongoing support to ensure your website stays secure, up-to-date, and performs optimally. Our team offers regular updates, troubleshooting, and proactive monitoring to address potential issues promptly.With a focus on user experience, we implement enhancements, and optimizations to keep your website at the forefront of technology.",
    },
    {
      id: 16,
      title: "Enterprise Webiste Applications",
      description:
        "Transform your business operations with Dotcnnct's Enterprise Website Applications. We specialize in developing robust, scalable, and tailored solutions that align with your enterprise needs. Our applications seamlessly integrate with diverse systems, streamlining processes and enhancing efficiency.Dotcnnct's Enterprise Website Applications go beyond standard functionalities, offering custom features to meet the unique demands of your business. ",
    },
  ];
  return (
    <Stack>
      <ServiceHeader
        image={image}
        title={"Pixel to Perfection: Dotcnnct's Artistry in Web Development"}
        description={
          "At DotCnnct, our web development wizards weave digital magic. From concept to code, we sculpt bespoke websites that tell your unique story, combining style and substance to leave a lasting impact."
        }
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          p={4}
          mt={4}
          justifyContent={"space-between"}
          backgroundColor={"white"}
        >
          <Box
            textAlign={"left"}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Web Desgining
            </Typography>
            <Typography variant="caption">
              Crafting the visual aesthetics and layout of your website. It
              involves the use of graphic design elements, user interface (UI)
              design, and user experience (UX) design to create an engaging and
              visually appealing website that effectively communicates your
              brand or message
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem></Divider>

          <Box>
            <Typography variant="h5">Web Hosting</Typography>

            <Typography variant="caption">
              Think of it as renting space on the internet to store and make
              your website accessible. Web hosting services provide the
              infrastructure and technologies needed for a website to be viewed
              online. It ensures that your website is available to users 24/7.
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            variant="fullWidth"
          ></Divider>

          <Box>
            <Typography variant="h5">App Development</Typography>

            <Typography variant="caption">
              Creating customized applications (apps) for mobile devices or
              desktops. This involves coding, designing, and testing to develop
              software that meets the specific needs of your business or
              audience. Apps can range from games and entertainment to
              productivity tools and e-commerce platforms.
            </Typography>
          </Box>
        </Stack>
      </ServiceHeader>

      <Stack textAlign={"center"} p={5}>
        <Typography variant="h4">Our Website Development Services</Typography>

        <Typography variant="caption" fontStyle={"italic"} p={2}>
          We provide end-to- end website solutions from the initial website
          consulting to final delivery and maintenance.<br></br>Take a look at
          the broad range of website development services we offer:
        </Typography>

        <Grid container spacing={2} p={3}>
          {cards.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <WebServices {...item} />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ p: 5 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} mt={8}>
              <Typography
                variant="h2"
                fontWeight={800}
                lineHeight={1.2}
                textAlign={"left"}
              >
                Why <br></br>Should I <br></br>Care<br></br> About
              </Typography>

              <Typography variant="h3" lineHeight={1.2} textAlign={"left"}>
                web development?
              </Typography>
              <Typography
                lineHeight={1.5}
                textAlign={"left"}
                fontWeight={"200"}
                fontSize={{ xs: "15px", md: "15px" }}
                variant="body2"
              >
                <br></br>A well-crafted website not only establishes your online
                presence but also serves as a powerful tool for brand
                representation, customer engagement, and business growth. It
                enhances user experience, making information easily accessible
                and interactions seamless. In an increasingly digital world, a
                professionally developed website is fundamental for credibility,
                customer trust, and staying competitive in your industry.
                Whether you're a startup or an established business, investing
                in web development is a strategic move to connect with your
                audience and unlock new opportunities.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <CardMedia
                component={"img"}
                height={"100%"}
                width="100%"
                image={image}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};

export default WebDesigning;
