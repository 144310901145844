import { Box, Card, CardActionArea, CardMedia } from "@mui/material";

const PartnerCard = ({ image }) => {
  return (
    <Box>
      <Card sx={{ maxWidth: 305, margin: 2 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="120"
            image={image}
            alt="bank Partners"
          />
        </CardActionArea>
      </Card>
    </Box>
  );
};
export default PartnerCard;
