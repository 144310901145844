import { Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import ServiceHeader from "../Component/ServiceHeader";
import image from "./../images/legal-proceeding.jpg";
import SoleProprietorship from "./../images/SoleProprietorship.jpg";
import Partnership from "./../images/Partnership.jpg";
import PrivateLimited from "./../images/PrivateLimited.jpg";
import LLP from "./../images/LLP.jpg";

import CompanyType from "../Component/CompanyType";
const servicesArray = [
  {
    title: "Business Registration and Incorporation",
    description:
      "We handle the paperwork and legal formalities to get your business officially registered and incorporated.",
    image: "business_registration_image.jpg",
  },
  {
    title: "Legal Consultation for Business Structure",
    description:
      "Receive expert advice on choosing the right business structure tailored to your goals and needs.",
    image: "legal_consultation_image.jpg",
  },
  {
    title: "Compliance and Regulatory Assistance",
    description:
      "Stay on the right side of the law with our assistance in navigating regulations and ensuring compliance.",
    image: "compliance_image.jpg",
  },
  {
    title: "Contract Drafting and Review",
    description:
      "Our legal experts will draft and review contracts to protect your interests and ensure clarity in agreements.",
    image: "contract_drafting_image.jpg",
  },
  {
    title: "Intellectual Property Guidance",
    description:
      "Safeguard your intellectual property with our guidance on trademarks, patents, and copyrights.",
    image: "intellectual_property_image.jpg",
  },
];
const businessAssistanceTypes = [
  {
    type: "Sole Proprietorship",
    description:
      "Embarking on a solo entrepreneurial journey? Our business assistance services for sole proprietors are designed to guide and support your individual business aspirations. Whether you're starting a new venture or expanding your existing one, we provide expert advice and assistance tailored to the unique needs of sole proprietors.",
    servicesProvided: [
      "Business Planning and Strategy",
      "Legal Compliance Guidance",
      "Financial Consultation",
    ],
    documentsRequired: [
      "Personal Identification (Government-issued photo ID)",
      "PAN Card",
      "Business Proof (Shop and Establishment Certificate, GST Registration, etc.)",
    ],
    image: SoleProprietorship, // Replace with the actual image filename
  },
  {
    type: "Partnership",
    description:
      "In the spirit of collaboration, our business assistance services for partnerships are here to guide and support the growth and development of your joint ventures. As partners in your success, we offer expert advice and assistance to help your partnership thrive and reach new heights.",
    servicesProvided: [
      "Partnership Agreement Assistance",
      "Legal Compliance Guidance",
      "Financial Consultation",
    ],
    documentsRequired: [
      "Partnership Deed",
      "Personal Identification (ID proof of all partners)",
      "PAN Card (PAN cards of all partners)",
      "Business Proof (GST Registration, Shop and Establishment Certificate, etc.)",
    ],
    image: Partnership, // Replace with the actual image filename
  },
  {
    type: "Private Limited Company",
    description:
      "Elevate your corporate ambitions with our business assistance services for private limited companies. As a cornerstone for growth, our expert advice and assistance are crafted to support the strategic expansion and development of your private limited company.",
    servicesProvided: [
      "Company Incorporation Guidance",
      "Legal Compliance Assistance",
      "Financial Consultation",
    ],
    documentsRequired: [
      "Certificate of Incorporation",
      "Memorandum and Articles of Association (MOA & AOA)",
      "Director Identification Number (DIN) for all directors",
      "Personal Identification (ID proof of directors)",
      "PAN Card (PAN cards of the company and all directors)",
    ],
    image: PrivateLimited, // Replace with the actual image filename
  },
  {
    type: "Limited Liability Company (LLC)",
    description:
      "For businesses seeking the balance of limited liability and operational flexibility, our business assistance services for Limited Liability Companies (LLCs) provide the guidance necessary for growth, expansion, and strategic initiatives.",
    servicesProvided: [
      "LLC Formation Assistance",
      "Legal Compliance Guidance",
      "Financial Consultation",
    ],
    documentsRequired: [
      "Certificate of Formation",
      "Operating Agreement",
      "Personal Identification (ID proof of all members)",
      "PAN Card (PAN cards of the LLC and all members)",
      "Business Proof (Relevant certificates and licenses)",
    ],
    image: LLP, // Replace with the actual image filename
  },
];

const LegalProceeding = () => {
  return (
    <Stack>
      <ServiceHeader
        title={"Legal Launchpad: Guiding Your Business Setup Journey"}
        description={
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus quasi corrupti et totam ad! Architecto tempore rem iste aut sequi accusantium voluptate minus! Omnis rerum ducimus necessitatibus dicta modi natus."
        }
        image={image}
      />

      <Stack textAlign={"center"} padding={4} gap={4}>
        <Typography variant="h4">
          Comprehensive Business Assistance Services
        </Typography>
        {businessAssistanceTypes.map((el) => (
          <CompanyType key={el.type} {...el} />
        ))}
      </Stack>
      <Divider />
      <Stack textAlign={"center"} padding={4} gap={4}>
        <Typography variant="h4">
          We'll Help You Deal Your Legal Documents
        </Typography>
        {servicesArray.map((el, index) => (
          <Stack
            direction={"row"}
            textAlign={"left"}
            mx={{ xs: 0, sm: 0 }}
            gap={2}
          >
            <IconButton
              sx={{
                width: 50,
                height: 50,
                color: "white",
                backgroundColor: "black",
              }}
            >
              {index + 1}
            </IconButton>
            <Stack>
              <Typography variant="h6">{el.title}</Typography>
              <Typography variant="caption">{el.description}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default LegalProceeding;
