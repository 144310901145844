import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
//  import image from "../images/pic_1.png";

const TeamCard = ({ image,title, description }) => {
  return (
    <Card>

      <CardMedia 
      sx={{ height: 140 }} 
     image ={image} 
      alt="green iguana" />

      <CardActionArea
        sx={{padding: 2,}}>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography
            gutterBottom
            variant="h5"
            // fontStyle="italic"
            component="div"
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            textAlign="left"
            fontWeight="500"
            color="text.secondary"
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TeamCard;
