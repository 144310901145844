import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import ButtonComponent from "./ButtonComponent";

const RootContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 4,
  gap: "20px",
  maxWidth: "500px",
  margin: "0 auto",
  marginTop: "20px",
});

const StyledTextField = styled(TextField)({
  margin: 1,
});
const buttonStyles = {
  backgroundColor: "black",
  color: "white",
  padding: "8px 15px",
  borderRadius: "5px",
};

const ContactForm = () => {
  return (
    <RootContainer>
      <Typography variant="h4" gutterBottom>
        Get in Touch
      </Typography>
      <StyledTextField label="Name" variant="outlined" fullWidth />
      <StyledTextField
        label="Email"
        variant="outlined"
        type="email"
        fullWidth
      />
      <StyledTextField
        label="Message"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
      />
      <Button fullWidth sx={buttonStyles}>
        Submit
      </Button>
    </RootContainer>
  );
};

export default ContactForm;
