import { Divider, Grid, Stack, Typography, Link, Box } from "@mui/material";
import React from "react";
import { Link as NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
const footerLinks = [
  {
    id: 1,
    title: "Menu",
    links: [
      { id: 1, title: "Home", isLink: true, isIcon: false, path: "/" },
      { id: 2, title: "About Us", isLink: true, isIcon: false, path: "/about" },
      {
        id: 3,
        title: "Business Loan",
        isLink: true,
        isIcon: false,
        path: "/services/business-loan",
      },
      {
        id: 3,
        title: "Web Designing",
        isLink: true,
        isIcon: false,
        path: "/services/web-designing",
      },
      {
        id: 3,
        title: "Digital Marketing",
        isLink: true,
        isIcon: false,
        path: "/services/digital-marketing",
      },
      {
        id: 3,
        title: "Legal Proceeding",
        isLink: true,
        isIcon: false,
        path: "/services/legal-proceeding",
      },
      {
        id: 4,
        title: "Contact",
        isLink: true,
        isIcon: false,
        path: "/contact",
      },
    ],
  },
  {
    id: 2,
    title: "Location",
    links: [
      {
        id: 1,
        title: "Block A Radhe Apartment Sunrise City ",
        isLink: false,
        isIcon: false,
        path: "/",
      },
      {
        id: 2,
        title: " Niwaru Jaipur,Rajasthan,302012",
        isLink: false,
        isIcon: false,
        path: "/",
      },
    ],
  },
  {
    id: 3,
    title: "Contact",
    links: [
      {
        id: 1,
        title: "+91 7014213711, +91 7014299152",
        isLink: false,
        isIcon: false,
        path: "/",
      },
      {
        id: 2,
        title: "info@dotcnnct.com",
        isLink: false,
        isIcon: false,
        path: "/",
      },
    ],
  },
  {
    id: 4,
    title: "Social",
    links: [
      {
        id: 1,
        title: "Facebook",
        isLink: false,
        isIcon: true,
        path: "/",
        icon: <FacebookIcon />,
      },
      {
        id: 2,
        title: "Twitter",
        isLink: false,
        isIcon: true,
        path: "/",
        icon: <TwitterIcon />,
      },
      {
        id: 3,
        title: "Instagram",
        isLink: false,
        isIcon: true,
        path: "/",
        icon: <InstagramIcon />,
      },
    ],
  },
];
const Footer = () => {
  return (
    <Stack padding={4} spacing={2}>
      <Divider flexItem />
      <Box>
        <Grid container spacing={2}>
          {footerLinks.map((el) => (
            <Grid item xs={12} md={3} key={el.id}>
              <Stack spacing={1}>
                <Typography variant="overline" fontSize={"20px"}>
                  {el.title}
                </Typography>
                {el.links.map((item) => {
                  if (item.isLink) {
                    return (
                      <Link
                        key={el.id}
                        component={NavLink}
                        to={item.path}
                        sx={{
                          textDecoration: "none",
                          color: "black",
                          ":hover": { color: "grey" },
                        }}
                      >
                        {item.title}
                      </Link>
                    );
                  } else if (!item.isLink && !item.isIcon) {
                    return <Typography key={el.id}>{item.title}</Typography>;
                  } else {
                    return item.icon;
                  }
                })}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider flexItem />
      <Typography variant="caption">© 2024 by DotCnnct™</Typography>
    </Stack>
  );
};

export default Footer;
