import React from "react";

import CardMedia from "@mui/material/CardMedia";
import { Card } from "@mui/material";

function One({ height, image, width }) {
  return (
    <Card sx={{ maxWidth: 430 }}>
      <CardMedia
        component="img"
        image={image}
        height={height}
        width={width}
        alt="green iguana"
      />
    </Card>
  );
}
export default One;
