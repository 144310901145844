import React from "react";
import image from "../images/pic_2.jpg";
import { Box, CardMedia, Grid, Stack, Typography } from "@mui/material";
import img from "../images/digital-marketing.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import TeamCard from "../Component/TeamCard";
import digital from "../images/Marketing.jpg";
import finance from "../images/financeTeam.jpg";
import legal from '../images/legalPrecisio.jpg';
import it from "../images/pic_1.png";

const TeamMember = [
  {
    id: 1,
    image:  finance,
    title: "Finance Team",
    description:
      "Dedicated to fueling business growth, our loan team at DotCnnct combines financial acumen with personalized service, offering tailored solutions that empower enterprises to thrive.",
  },
  {
    id: 2,
    image:  it ,
    title: "IT Team",
    description:
      "The web development team at DotCnnct is a creative force, crafting engaging and functional online spaces. With expertise in design and technology, they bring digital visions to life.",
  },
  {
    id: 3,
    image:  digital ,
    title: "Digital Marketing Team",
    description:
      "Driving digital success, our marketing team impactful strategies, enhancing brand visibility and engagement. Their expertise ensures businesses stand out in the online landscape.",
  },
  {
    id: 4,
    image: legal ,
    title: "Legal Department Team",
    description:
      "Safeguarding business interests, our legal precision team navigates complexities with finesse. Committed to compliance and security, they provide a solid foundation for sustained success.",
  },
];

function AboutUs() {
  return (
    <Stack>
      <Box
        sx={{
          backgroundColor: "#e0e0e0",
          textAlign: "left",
          p: 5,
          fontWeight: "400",
          pt: 10,
        }}
      >
        <Typography
          className="animate__animated animate__slideInLeft"
          variant="h2"
          sx={{
            width: { xs: "100%", md: "50%" },
          }}
        >
          About
        </Typography>

        <Typography
          sx={{
            width: { xs: "100%", md: "50%" },
          }}
          className="animate__animated animate__slideInLeft"
          fontWeight={"200"}
          pt={{ xs: 4, md: 7 }}
          pb={{ xs: 4, md: 8 }}
        >
          From tailored business loans and precision legal support to
          cutting-edge website and app development, topped with strategic
          digital marketing solutions – we connect the dots for your business
          success."
        </Typography>
      </Box>

      <Box sx={{ textAlign: "left", p: 5 }}>
        <Typography variant="h3">Our Story</Typography>

        <Stack direction={{ xs: "column", md: "row" }} spacing={3} mt={2}>
          <Typography
            fontWeight={"200"}
            lineHeight={1.5}
            fontSize={{ xs: "15px", md: "15px" }}
          >
            our journey began with a simple yet powerful idea – to empower
            businesses to reach their full potential. Established with a vision
            of seamless connectivity and comprehensive support, DotCnnct emerged
            as a beacon for entrepreneurs navigating the complexities of the
            business world. Founded by a team of passionate individuals, each
            with a unique expertise spanning finance, technology, legal, and
            marketing, DotCnnct was conceived as a one-stop solution for
            businesses of all sizes.
          </Typography>

          <Typography
            // pt={{ xs: 4, md: 2 }}
            fontWeight={"200"}
            lineHeight={1.5}
            fontSize={{ xs: "15px", md: "15px" }}
          >
            As our journey unfolds, DotCnnct continues to embody its founding
            principles DotCnnct has evolved into the go-to one-stop solution for
            businesses of all sizes. Our story is a testament to dedication,
            innovation, and the relentless pursuit of excellence in navigating
            the intricate realms of the business world Born from the collective
            passion of a diverse team, each member contributing unique expertise
            in finance, technology, legal, and marketing, DotCnnct has evolved
            into the go-to one-stop solution for businesses of all sizes. ."
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ p: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" lineHeight={2}>
              Our Visions
            </Typography>
            <Typography
              lineHeight={1.5}
              textAlign={"left"}
              fontWeight={"200"}
              fontSize={{ xs: "15px", md: "15px" }}
              variant="body2"
            >
              Our vision is centered on empowerment. We see a world where
              entrepreneurs <br></br>
              and visionaries are empowered to turn their dreams into reality.
              DotCnnct <br></br>
              strives to be the enabler, providing the financial, technological,
              legal, <br></br>
              and marketing support necessary for businesses to not only survive
              but <br></br>
              to flourish.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardMedia
              component={"img"}
              height={"100%"}
              width="100%"
              image={image}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: "#e0e0e0", padding: 4 }}>
        <Typography variant="h3" textAlign="center">
          Our Team
        </Typography>

        <Grid container spacing={2} marginTop="10px">
          {TeamMember.map((item, index) => (
            <Grid item xs={12} md={3} key={item.id}>
              <ScrollAnimation
                animateIn="animate__fadeInLeft"
                animateOnce
                duration={(index + 1) / 2}
              >
                <TeamCard {...item} />
              </ScrollAnimation>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}

export default AboutUs;
