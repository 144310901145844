import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Component/Navbar";
import Services from "./Pages/Services";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import Home from "./Pages/Home";
import BusinessLoan from "./Pages/BusinessLoan";
import Footer from "./Component/Footer";
import WebDesigning from "./Pages/WebDesigning";
import DigitalMarketing from "./Pages/DigitalMarketing";
import LegalProceeding from "./Pages/LegalProceeding";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="services" element={<Services />} />
        <Route path="services/business-loan" element={<BusinessLoan />} />
        <Route path="services/web-designing" element={<WebDesigning />} />
        <Route
          path="services/Digital-marketing"
          element={<DigitalMarketing />}
        />
        <Route path="services/legal-proceeding" element={<LegalProceeding />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
