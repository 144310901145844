import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Link,
  Stack,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

const links = [
  { label: "Home", to: "/" },
  { label: "About Us", to: "/about" },
  {
    label: "Services",
    to: "/services",
    sublinks: [
      { label: "Business Loan", to: "/services/business-loan" },
      { label: "Web Designing", to: "/services/web-designing" },
      { label: "Digital Marketing", to: "/services/digital-marketing" },
      { label: "Legal Proceeding", to: "/services/legal-proceeding" },
    ],
  },
  { label: "Contact", to: "/contact" },
];

function Navbar() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);

  const handleClick = (index) => {
    setOpen(open === index ? null : index);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isTop = scrollPosition === 0;

      setIsScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        background: isScrolled ? "white" : "transparent",
        transition: "background 0.3s ease",
        boxShadow: !isScrolled && "none",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: isScrolled ? "black" : "white",
          }}
        >
          DotCnnct
        </Typography>
        <Stack
          direction={"row"}
          spacing={4}
          display={{ xs: "none", md: "flex" }}
        >
          {links.map((link) => (
            <React.Fragment key={link.label}>
              {link.sublinks ? (
                <Box>
                  <Link
                    aria-controls={`${link.label}-menu`}
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                    sx={{
                      color: isScrolled ? "black" : "white",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      p: 1,
                      ":hover": { color: "grey" },
                    }}
                  >
                    {link.label}
                    {<ExpandMore />}
                  </Link>
                  <Menu
                    id={`${link.label}-menu`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    slotProps={{
                      paper: {
                        sx: {
                          backgroundColor: isScrolled ? "white" : "transparent",
                          boxShadow: "none",
                        },
                      },
                    }}
                  >
                    {link.sublinks.map((sublink) => (
                      <MenuItem
                        key={sublink.label}
                        onClick={handleMenuClose}
                        sx={{
                          px: 1,
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Link
                          sx={{
                            color: isScrolled ? "black" : "white",
                            textDecoration: "none",
                            ":hover": { color: "grey" },
                          }}
                          component={NavLink}
                          to={sublink.to}
                        >
                          {sublink.label}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Link
                  sx={{
                    color: isScrolled ? "black" : "white",
                    textDecoration: "none",
                    ":hover": { color: "grey" },
                    p: 1,
                  }}
                  component={NavLink}
                  to={link.to}
                >
                  {link.label}
                </Link>
              )}
            </React.Fragment>
          ))}
        </Stack>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleDrawerOpen}
          sx={{
            display: { md: "none" },
          }}
        >
          <MenuIcon style={{ color: isScrolled ? "black" : "white" }} />
        </IconButton>
        {/* Drawer for mobile view */}
        <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
          <List>
            {links.map((link, index) => (
              <React.Fragment key={index}>
                <ListItem onClick={() => handleClick(index)}>
                  <ListItemText>
                    <Link
                      component={NavLink}
                      to={link.to}
                      style={{
                        color: "black",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {link.label}
                      {link.sublinks && <ExpandMore />}
                    </Link>
                  </ListItemText>
                </ListItem>
                {link.sublinks && (
                  <Collapse in={index === open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {link.sublinks.map((sublink, subIndex) => (
                        <ListItem key={subIndex} onClick={handleDrawerClose}>
                          <ListItemText>
                            <Link
                              component={NavLink}
                              to={sublink.to}
                              style={{
                                color: "black",
                                textDecoration: "none",
                              }}
                            >
                              {sublink.label}
                            </Link>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
