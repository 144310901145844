import { Box, CardMedia, Grid, Typography } from "@mui/material";
import image from "../images/business-lone.jpg";
import ButtonComponent from "./ButtonComponent";
import { useNavigate } from "react-router";

const Contact = () => {
  const navigate = useNavigate();
  return (
    <Box p={4} py={10}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <CardMedia
            component={"img"}
            height={"100%"}
            width="100%"
            image={image}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography lineHeight={1.5} textAlign={"left"} variant="body2">
            "Connect with us to explore endless possibilitiesfor your business.{" "}
            <br></br>
            Our team at DotCnnct is eager to hear from you, ready to provide{" "}
            <br></br>
            tailored solutions for your needs. Reach out today, and let's embark{" "}
            <br></br>
            on a journey to elevate your business to new heights!"
          </Typography>
          <ButtonComponent title="Contact us" onClick={()=>{
          navigate("contact")}}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
