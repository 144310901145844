import {
  CardMedia,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CompanyType = ({
  image,
  description,
  type,
  servicesProvided,
  documentsRequired,
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} overflow={"hidden"}>
          <CardMedia image={image} component={"img"} width="100%" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack textAlign={"left"}>
            <Typography variant="h5" gutterBottom>
              {type}
            </Typography>
            <Typography variant="caption">{description}</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <ListItem>
                  <ListItemText>Services Provided</ListItemText>
                </ListItem>
                {servicesProvided.map((el, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText>{el}</ListItemText>
                  </ListItem>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <ListItem>
                  <ListItemText>Documents Required</ListItemText>
                </ListItem>
                {documentsRequired.map((el, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText>{el}</ListItemText>
                  </ListItem>
                ))}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CompanyType;
