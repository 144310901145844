import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const WebServices = ({ title, description }) => {
  return (
    <Card sx={{ maxWidth: 330 }}>
      <CardContent sx={{ textAlign: "left" }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>

        <Typography variant="caption" textAlign={"left"} gutterBottom>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WebServices;
