import React from "react";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { CiTablets1 } from "react-icons/ci";

const FeatureComponent = ({ title, content }) => {
  return (
    <Card>
      <CardActionArea
        sx={{
          padding: 2,
        }} >
        <CardContent sx={{ textAlign: "center" }}>
          <CiTablets1 size={50} />

          <Typography
            gutterBottom
            variant="h5"
            // fontStyle="italic"
            component="div"
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            textAlign="left"
            fontWeight="500"
            color="text.secondary"
          >
            {content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default FeatureComponent;
