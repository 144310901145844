import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Paper,
  Slider,
  Input,
  InputAdornment,
  Stack,
  Grid,
} from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";

const EMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestRate, setInterestRate] = useState(5);
  const [loanTerm, setLoanTerm] = useState(12);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const calculateEMI = useCallback(() => {
    const principal = loanAmount;
    const rate = interestRate / 1200; // Monthly interest rate
    const term = loanTerm; // Loan term in months

    const emi =
      (principal * rate * Math.pow(1 + rate, term)) /
      (Math.pow(1 + rate, term) - 1);

    const totalInterestAmount = emi * term - principal;

    setMonthlyPayment(emi.toFixed(2));
    setTotalPayable((emi * term).toFixed(2));
    setTotalInterest(totalInterestAmount.toFixed(2));
  }, [loanAmount, interestRate, loanTerm]);
  useEffect(() => {
    calculateEMI();
  }, [calculateEMI]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: "20px", height: "320px" }}>
          <Typography align="center" gutterBottom>
            EMI Calculator
          </Typography>
          <div style={{ marginBottom: "20px" }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <Typography variant="body1">Loan Amount</Typography>
              <Input
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">₹</InputAdornment>
                }
              />
            </Stack>

            <Slider
              value={loanAmount}
              min={5000}
              max={10000000}
              step={5000}
              onChange={(e, value) => setLoanAmount(value)}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <Typography variant="body1">Interest Rate</Typography>
              <Input
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Stack>
            <Slider
              value={interestRate}
              min={1}
              max={20}
              step={0.1}
              onChange={(e, value) => setInterestRate(value)}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <Typography variant="body1">Loan Term (months)</Typography>
              <Input
                value={loanTerm}
                onChange={(e) => setLoanTerm(e.target.value)}
              />
            </Stack>
            <Slider
              value={loanTerm}
              min={6}
              max={72}
              step={1}
              onChange={(e, value) => setLoanTerm(value)}
            />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3}>
          <Stack alignItems={"center"}>
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: totalPayable,
                      label: `Total Payable`,
                    },
                    { id: 1, value: totalInterest, label: "Total Interest" },
                  ],
                  arcLabel: (item) => `${item.value}`,
                  arcLabelMinAngle: 45,
                  innerRadius: 30,
                  outerRadius: 110,
                  paddingAngle: 5,
                  cornerRadius: 5,
                  cx: 150,
                  cy: 150,
                  highlightScope: { faded: "global", highlighted: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              slotProps={{
                legend: {
                  direction: "row",
                  position: { vertical: "bottom", horizontal: "middle" },
                  padding: -5,
                },
              }}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontWeight: "bold",
                },
              }}
              height={300}
              width={300}
            />
            <Typography
              variant="overline"
              align="center"
              fontSize={"20px"}
              fontWeight={"bold"}
              gutterBottom
            >
              Monthly EMI: ₹{monthlyPayment}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EMICalculator;
