import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid } from "@mui/material";

const BusinessLoanEligibility = () => {
  return (
    <Box p={4} bgcolor={"#e0e0e0"}>
      <CardContent>
        <Typography variant="h4" component="div" textAlign={"center"}>
          Business Loan Eligibility Criteria and Documentation
        </Typography>
        <br />
        <Typography textAlign={"center"} component="div" variant="overline">
          To avail of a business loan, you are required to be:
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <ListItemText>Eligibility Criteria</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="Be above 21 years of age" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="Be a resident of India" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="Have a business income of more than 10,000 per month" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <ListItemText>Documents Required</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="PAN card" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="Address proof (either Aadhaar or Passport)" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="Business / Income related document" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary="Proof of business documents" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default BusinessLoanEligibility;
